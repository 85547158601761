//@import "./normalize.scss";
/* You can add global styles to this file, and also import other style files */

@import "pjtheme.scss";

/*
@font-face {
    font-family: 'Roboto';
    src: url("~src/assets/fonts/Roboto/Roboto-Regular.ttf")
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url("~src/assets/fonts/Roboto/Roboto-Bold.ttf")
}
@font-face {
    font-family: 'Roboto-Black';
    src: url("~src/assets/fonts/Roboto/Roboto-Black.ttf")
}



*/





body{
    margin: 0px;
 // enable  print ing background  https://stackoverflow.com/a/14784655/1163224
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;


}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25){

}


.global-font-type{
    font-family: Raleway, Regular;

}
html, body { height: 100%; }
body { margin: 0; font-family: Raleway, "Raleway", sans-serif; }

.pjcolor{
    color: #FF2744;
}
.signup-dialog-container{
    --mdc-dialog-subhead-font: "Raleway";
    border-radius: 20px;
    --mdc-shape-medium: 20px;
    overflow-x: hidden;

    .mat-mdc-dialog-container{
        --mdc-dialog-container-color: white

    }
}

.signup-dialog-container  .mat-dialog-content {

    margin: 0;
    padding: 0;
}
.signup-dialog-container .mat-dialog-title{
    margin: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 10px;
    font: normal normal normal 22px Raleway;
    font-weight: lighter;
}

.language-menu {
    --mdc-theme-surface: white;
    --mdc-theme-text-primary-on-background:  #000;
    background-color: white !important;
}

.language-menu-dark{
    --mdc-theme-surface:#211557;
}
.mat-menu-panel.language-menu-dark {
    --mdc-theme-surface:#211557;
    background-color: #211557 !important;
}


.mat-menu-panel.language-menu .mat-menu-item{
    color: #000000;
}


.mat-menu-panel.language-menu-dark .mat-menu-item{
    color: white;
}

.mat-menu-panel.language-menu .mat-menu-item:hover{
        background-color: #F0F4FE;
    }

    .mat-menu-panel.language-menu-dark .mat-menu-item:hover{
        background-color: #f0f4fe16;
    }



    // home page
.mat-accordion.home-expand-mat .mat-expansion-panel{
    background-color: transparent;

    .mat-expansion-panel-body{
        padding: 0px;
    }

    .mat-expansion-panel-header:hover {
        background: transparent;
    }

    .mat-expansion-panel-header.mat-expanded{
        height: initial;
    }


}

.mat-accordion.home-expand-mat .mat-expansion-panel:not([class*=mat-elevation-z]) {

    box-shadow: none;
}

.mat-accordion.home-expand-mat  .mat-expansion-panel-header{
    padding: 5px;
    padding-left: 0px;
    height: initial;
}

.mat-accordion.home-expand-mat  .mat-expansion-indicator::after{
    color: white;
}

.campaign-check-box {


    .mat-checkbox-inner-container{
    width: 21px;
    height: 21px;

    }
    .mat-checkbox-frame{
        border-color: white;

    }
    .mat-checkbox-checkmark-path {
        stroke: white !important;
    }

}

.campaign-check-box.os-windows{
    .mat-checkbox-inner-container{
        width: calc(21px * $firefoxScale);
        height:  calc(21px * $firefoxScale);
    }

}

.campaign-check-box{
    &.mat-checkbox-checked{
        .mat-checkbox-background{
            background-color: #FF2744;
        }
    }
}

.robo-font{
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.campaign-stats-title{
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    color: black;
}

.campaign-stats-title.os-windows{
    margin-top: 0px;
    font-size: calc(1.1rem * $firefoxScale);
}

.apexcharts-tooltip{
    background-color: #211557 !important;

    span{
        color: white !important;

    }
}

.interest-tooltip{
    background-color: #211557 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;

    font-family: Roboto !important;
    font-size: 15px;
    font-weight: 500;
}

.logo-en-us{
    background-image: url("~src/assets/icons/logo_white.svg");
}
.logo-zh-cn{
    background-image: url("~src/assets/icons/logo_white_cn.svg");
}


.label-cont{
    align-items: center;
}

.signup{
    &.link{
        color: #FF2744;
        text-decoration: none;
    }
    &.link.bold{
        font-weight: bold;
    }
}

/* printing styles */

.content-info.lang-zh-cn >  * span.campaign-stats-title{

  font-weight: 550 !important;
}

.content-info.lang-zh-cn > * span.label-info{
  font-weight: 550 !important;
}


.content-info.lang-zh-cn > * .tabllabel{
  font-weight: 550 !important;
}

app-interest-distrib.lang-zh-cn > * span.itemName{
  font-weight: 550 !important;
  font-size: 13px !important;
}


app-overall-numbers.lang-zh-cn * span.subLabel,
app-overall-numbers-print.lang-zh-cn > * span.subLabel{
  font-size: 20px !important;
}
app-candidates-list.lang-zh-cn > * .tab-container > span,
app-candidates-list.lang-zh-cn > * .achieved-stats > span
{
  font-weight: 550 !important;

}
app-sample-slider .main-container.lang-zh-cn{
  font-weight: 550 !important;
}
app-filter-sample.lang-zh-cn{
  font-weight: 550 !important;
}


.print-logo{
    img{
        height: calc(70px * 0.75) !important;
    }

}