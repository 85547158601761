
//src/app/sharedlib/style-util.service.ts

$firefoxScale: 0.8;
$edgeScale: 0.8;


ngx-tooltip-content > * .apexcharts-tooltip-title{

    text-align: center;
}
ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {

    .apexcharts-tooltip-title{

        text-align: center !important;
    }
    .tooltip-caret.position-top {
   
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    }

    .tooltip-content > *{
        padding: 0 !important;
        .tooltip-label{
            font-family: Roboto !important;
                
            border-bottom:  1px solid white;
        }
        .tooltip-label::after{
            content: " ";
            border-bottom: 1px solid white;
        }
       .tooltip-val{
            display: none;
        
        }

    }
    background-color: #211557 !important;
    font-family: Roboto !important;
    font-size: 15px;
    font-weight: 500;
    padding: 0px;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;

 }

 .post-detail-dialog mat-dialog-container{
    --mdc-dialog-container-color: white;
    --mdc-shape-medium: 20px;
    background-color: white;
    border-radius: 20px;
 }

 .pdf-loader-dialog mat-dialog-container{
    --mdc-dialog-container-color: rgba(255, 255, 255, 1);
    --mdc-shape-medium: 10px;
    --mdc-dialog-container-shape: 30px;

    box-shadow: 0px 3px 20px #8D99AE33;

    border-radius: 50px;
   // border: 1px solid rgba(128, 128, 128, 0.605);


 }

 .pdf-loader-bar {
    --mdc-linear-progress-active-indicator-color:#ffffff;
    --mdc-linear-progress-track-height: 10px;

    border: solid  1px #ff2361;
    border-radius: 20px;
    .mdc-linear-progress {
        border-radius: 20px;
    }
    .mdc-linear-progress__buffer-bar{
        background-color: #ff2361;
    }
 }

 .mtooltip-cont{
    --mdc-plain-tooltip-supporting-text-font: Roboto;
    --mdc-plain-tooltip-container-color: #211557;
    --mdc-plain-tooltip-container-shape: 5px;
    --mdc-plain-tooltip-supporting-text-weight: lighter;
    .mat-tooltip-hide { display: none!important; }

    .mdc-tooltip__surface-animation{
     
    }
    .mdc-tooltip__surface{
        padding-top: 5px ;
        padding-bottom: 5px ;
        padding-left: 20px ;
        padding-right: 20px;
        border: 1px solid white !important;
    }
 
}

.profileMenu{
   

    --mdc-theme-on-surface: black;
    --mdc-theme-surface: transparent;
    --mdc-shape-medium: 15px
}
.profileMenu > .mat-mdc-menu-content{

    padding: 0px !important;
    
}

.iti__selected-dial-code{
    /* font-family: Roboto !important; */
     font-size: 20px;
 }
 .iti__selected-flag{
 
 }

 
